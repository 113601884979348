export const HEADER_HEIGHT = 80;
export const FOOTER_HEIGHT = 80;
export const HEADER_HEIGHT_MOBILE = 50;
export const FOOTER_HEIGHT_MOBILE = 50;
export const DESKTOP_4K_BREAKPOINT = 2560;
export const DESKTOP_EXTRALARGE_BREAKPOINT = 1640;
export const DESKTOP_LARGE_BREAKPOINT = 1440;
export const DESKTOP_MEDIUM_BREAKPOINT = 1280;
export const DESKTOP_BREAKPOINT = 1024;
export const TABLET_BREAKPOINT = 768;
export const MOBILE_LARGE_BREAKPOINT = 425;
export const MOBILE_MEDIUM_BREAKPOINT = 375;
export const MOBILE_SMALL_BREAKPOINT = 320;

export const ESRI_BANNER_HEIGHT = 16

export const MONGOLIA_URL_2D = "https://services-eu1.arcgis.com/XU4ojXGttjPrkEAc/arcgis/rest/services/240613_NK___2D_Masterplan_gdb/FeatureServer"
export const MONGOLIA_URL_3D = "https://tiles-eu1.arcgis.com/XU4ojXGttjPrkEAc/arcgis/rest/services/NK_3D_Massing_Updated_P1_P3/SceneServer"
export const MONGOLIA_PHASE4_URL_3D = "https://tiles-eu1.arcgis.com/XU4ojXGttjPrkEAc/arcgis/rest/services/NK_3D_P4_Massing_Updated/SceneServer"
export const MONGOLIA_PHASE4_URL_2D = "https://services-eu1.arcgis.com/XU4ojXGttjPrkEAc/arcgis/rest/services/NK_2D_Masterplan_PHASE_4/FeatureServer"
export const RENDER_LOCATION_LAYER_URL = "https://services-eu1.arcgis.com/XU4ojXGttjPrkEAc/arcgis/rest/services/render_location/FeatureServer"


// Attributes filtered out of analysis
export const EXCLUDED_ATTRIBUTES = [
  "objectid",
  // "shape__length",
  // "shape__area",
  "uniqueid",
  "xw_id",
  "tc_code",
  "prj_name",
  "mp_ver",
  "mp_rev",
  "mp_sta",
  "des_status",
  "cre_date",
  "app_date",
  "mp_lead",
  "mp_sub",
  "cre_by",
  "mp_rep_li",
  "blo_id",
  "fid",
  "speckle_id",
  "area",
];

// Attributes filtered out of analysis
export const EXCLUDED_ATTRIBUTES_FROM_PANEL = [
  "speckle_id"
];

export const COLOUR_SCHEME_3 = [
  "#84B683", // Green - T1
  "#B4DA8E", // Lime - T2
  "#F5F1C5", // Light grayish yellow - T3
  "#EFDF81", // Soft yellow - T4
  "#EFC298", // very soft orange -T5
  "#EF958C", // very soft red - T6
  "#D0676C", // moderate red - T7
  "#B6D8E2", // Light grayish cyan -TS
  "#ffe119", // Yellow
  "#4363d8", // Blue
  "#f58231", // Orange
  "#008080", // Teal
  "#e6beff", // Lavender
  "#9A6324", // Brown
  "#fffac8", // Beige
  "#800000", // Maroon
  "#aaffc3", // Mint
  "#808000", // Olive
  "#ffd8b1", // Apricot
  "#000075", // Navy
  "#808080", // Grey
];