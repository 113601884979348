import React, {
    useState,
    createContext,
    useContext,
    ReactNode,
    useCallback,
  } from "react";
import { MONGOLIA_URL_2D } from "../../constants";
  
  interface LayerContextType {
    allLayers: any[];
    layers: any[];
    layerFilters: { [key: string]: any };
    layerStyle: {
        [layerName: string]: {
          style: string | null;
          type: "discrete" | "continuous";
        };
    };
    addLayer: (newLayer: string) => void;
    removeLayer: (layerToRemove: string) => void;
    initialiseLayers: (newLayers: string[]) => void;
    setLayers: (newLayers: string[]) => void;
    setLayerFilters: (layerId: string, data: any, attributeName?: string) => void;
    setLayerStyle: (
      layerName: string,
      newStyle: string | null,
      styleType: "discrete" | "continuous"
    ) => void;
  }
  
  const LayerContext = createContext<LayerContextType | null>(null);
  
  export const useLayerContext = () => {
    const context = useContext(LayerContext);
    if (!context) {
      throw new Error("useLayer must be used within a LayerProvider");
    }
    return context;
  };
  
  interface LayerProviderProps {
    children: ReactNode;
  }
  
  export const LayerProvider: React.FC<LayerProviderProps> = ({ children }) => {
    const [allLayers, setAllLayers] = useState<any[]>([]);
    const [layers, setLayerState] = useState<any[]>([]);
    const [layerFilters, setLayerFilterState] = useState<{ [key: string]: any }>({});
    const [layerStyle, setLayerStyleState] = useState<{
    
        [layerName: string]: {
          style: string | null;
          type: "discrete" | "continuous";
        };

    }>({

        [MONGOLIA_URL_2D]: {
          style: "TC_TRANSECT",
          type: "discrete",
        },
      
    });
    const addLayer = (layer: any) => {
      if (!layers.some((l) => l.name === layer.name)) {
        setLayerState((prevLayers) => [...prevLayers, layer]);
      }
    };
  
    const removeLayer = (layer: any) => {
      setLayerState((prevLayers) =>
        prevLayers.filter((l) => l.name !== layer.name)
      );
    };
  
    const initialiseLayers = useCallback((newLayers: any[]) => {
      setAllLayers(newLayers);
      setLayerState(newLayers);
    }, []);
  
    const setLayers = (newLayers: any[]) => {
      setLayerState(newLayers);
    };
  
    const setLayerFilters = useCallback((layerID: string, data: any, attributeName?: string) => {
      setLayerFilterState(prevFilter => {
        
        // Case 1: Set or add a whole layer value
        if (!attributeName) {
          return { ...prevFilter, [layerID]: data };
        }
    
        // Case 2: Set the value of an attribute or add a new attribute
        if (prevFilter[layerID]) {
          return {
            ...prevFilter,
            [layerID]: {
              ...prevFilter[layerID],
              [attributeName]: data
            }
          };
        }
    
        // If the layer does not exist, create it and add the attribute
        return {
          ...prevFilter,
          [layerID]: { [attributeName]: data }
        };
      });
    }, []);
  
    const setLayerStyle = useCallback(
      (
        layerName: string,
        newStyle: string | null,
        styleType: "discrete" | "continuous" = "discrete"
      ) => {
        setLayerStyleState((prevStyle) => {
          let newStyleState = { ...prevStyle };
  
          if (!newStyleState) {
            newStyleState = {};
          }
  
          newStyleState[layerName] = {
            style: newStyle,
            type: styleType,
          };
  
          return newStyleState;
        });
      },
      []
    );
  
    return (
      <LayerContext.Provider
        value={{
          allLayers,
          layers,
          layerFilters,
          layerStyle,
          addLayer,
          removeLayer,
          initialiseLayers,
          setLayers,
          setLayerFilters,
          setLayerStyle
        }}
      >
        {children}
      </LayerContext.Provider>
    );
  };
  