import React from 'react'
import { Navigate, Outlet } from 'react-router';
import useAuthStatus from '../../hooks/FirebaseAuth';
import Spinner from '../../components/Spinner';

const PrivateRoute = () => {
    const {loggedIn, checkingStatus} = useAuthStatus();
    if(checkingStatus){
        return <Spinner />
    }
  return loggedIn ? <Outlet /> : <Navigate to='/' />;
}

export default PrivateRoute;