import React, { useRef, useState, useContext, useEffect } from "react";
import { EXCLUDED_ATTRIBUTES, HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from "../../constants";
import { FaRegCircleUser } from "react-icons/fa6";
import NKLOGO from "../../images/animated-logo.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useLayerContext } from "../../context/LayerContext";
import { RxHamburgerMenu } from "react-icons/rx";
import { ImCross } from "react-icons/im";
import { auth, db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import ReactDOM from "react-dom";
import useAuthStatus from "../../hooks/FirebaseAuth";
import esriId from "@arcgis/core/identity/IdentityManager";
import { MobileContext } from "../../context/MobileContext";
import { getReadableName } from "../../pages/Masterplan/GeoSpatialUtils";

interface NavItemType {
  id: string;
  name: string;
  private?: boolean;
  isDropdown?: boolean;
  dropdownItems?: NavItemType[];
  action?: () => void;
}

/**
 * A component that renders the header of the application.
 * The header includes navigation items, user profile, and handles navigation.
 *
 * @param {Object} props - The component props.
 * @param {boolean} [props.minimalHeader=false] - Whether to render a minimal header without navigation items.
 *
 * @returns {JSX.Element} The rendered Header component.
 */
const Header: React.FC<{ minimalHeader?: boolean }> = ({
  minimalHeader = false,
}) => {
  const [currentPage, setCurrentPage] = useState<string>("");
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [mobileMenuView, setMobileMenuView] = useState<NavItemType[] | null>(
    null
  );
  const [hoverMessage, setHoverMessage] = useState("");
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const useMobileDesign = useContext(MobileContext);

  // Context to change feature layer
  const { allLayers, setLayers, setLayerStyle } = useLayerContext();
  const { checkingStatus } = useAuthStatus();

  /** Logs out user */
  const logout = () => {
    esriId.destroyCredentials();
    setCurrentPage("");
    auth.signOut();
    navigate("/");
  };

  useEffect(() => {
    const extractPageName = (path: string) => {
      const page = path.split("/")[1];
      return page ? page : "home";
    };

    setCurrentPage(extractPageName(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // Fetch user's first name and last name from Firestore
        const userRef = doc(db, "users", authUser.uid);
        getDoc(userRef)
          .then((doc) => {
            if (doc.exists()) {
              const userData = doc.data();
              setHoverMessage(`Hi ${userData.firstName} ${userData.lastName}`);
            }
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
          });
      } else {
        // User is signed out
        setHoverMessage("");
      }
    });
    return () => unsubscribe();
  }, []);

  /** Navigates to new page */
  const setPage = (item: string, state?: any) => {
    // setCurrentPage(item);
    navigate(`/${item}`, { state: state });
  };

  /** Called when masterplan item is selected. */
  const navigateToLayer = (
    layer: any,
    style: string | null = null,
    styleType: "discrete" | "continuous" = "discrete"
  ) => {
    // Update layer context if specified, otherwise enable all layers
    if (layer) {
      setLayerStyle(layer?.name, style, styleType);
      setLayers([layer]);
    } else {
      // Reset layers to default
      allLayers?.forEach((layer) => {
        setLayerStyle(layer?.name, style, styleType);
      });
      setLayers(allLayers);
    }

    if (currentPage !== "masterplan") {
      setPage("masterplan");
    }
  };

  /** Categorizes attribute names for polygon layers into discrete (string) and continuous (numerical) */
  const categorizeAttributes = (
    layer: any
  ): { discrete: string[]; continuous: string[] } => {
    if (
      layer.geometryType !== "esriGeometryPolygon" &&
      layer.geometryType !== "esriGeometryPolyline" &&
      layer.geometryType !== "esriGeometryPoint"
    ) {
      return { discrete: [], continuous: [] };
    }

    const attributesCategorized = {
      discrete: new Set<string>(),
      continuous: new Set<string>(),
    };

    layer.features.forEach((feature: any) => {
      Object.entries(feature.attributes).forEach(
        ([attributeName, attributeValue]) => {
          // Convert attributeName to lowercase for a case-insensitive comparison
          const attributeNameLower = attributeName.toLowerCase();
          // Check if attributeNameLower is in the exclude list
          if (!EXCLUDED_ATTRIBUTES.includes(attributeNameLower)) {
            // Check if value is a string (discrete)
            if (typeof attributeValue === "string") {
              attributesCategorized.discrete.add(attributeName);
            }
            // Check if value is a number (continuous)
            else if (typeof attributeValue === "number") {
              attributesCategorized.continuous.add(attributeName);
            }
          }
        }
      );
    });

    return {
      discrete: Array.from(attributesCategorized.discrete),
      continuous: Array.from(attributesCategorized.continuous),
    };
  };

  // Filter out Public Transport Network layers from masterplanItems
  const masterplanItems = allLayers
    .map((layer) => {
      if (layer.name === "site_boundary") {
        return {
          id: layer.id,
          name: getReadableName(layer.name),
          action: () => navigateToLayer(layer),
        };
      }
      const { discrete, continuous } = categorizeAttributes(layer);

      // Merge discrete and continuous attributes for dropdown
      const attributeItems = [
        ...discrete.map((attributeName) => ({
          id: `${layer.id}-${attributeName}`,
          name: getReadableName(attributeName),
          action: () => navigateToLayer(layer, attributeName, "discrete"),
        })),
        ...continuous.map((attributeName) => ({
          id: `${layer.id}-${attributeName}`,
          name: getReadableName(attributeName),
          action: () => navigateToLayer(layer, attributeName, "continuous"),
        })),
      ];

      // Dynamically generate dropdownItems based on attributes and include a "default" item
      const dropdownItems =
        attributeItems.length > 0
          ? [
            {
              id: `${layer.id}-default`,
              name: "Default",
              action: () => navigateToLayer(layer),
            },
            ...attributeItems,
          ]
          : undefined; // Don't add dropdownItems if no attributes are available

      return {
        id: layer.id,
        name: getReadableName(layer.name),
        action: () => navigateToLayer(layer),
        dropdownItems,
        isDropdown: !!dropdownItems,
      };
    });

  const navItems: Array<NavItemType> = [
    {
      id: "masterplan",
      name: "Masterplan",
      isDropdown: masterplanItems?.length > 0,
      dropdownItems: masterplanItems,
    },
    { id: "visualisation", name: "Visualisation" },
  ];

  // Determine mobile menu contents
  const mobileMenuContents = mobileMenuView ? mobileMenuView : navItems;

  // Check if used is on any logged out pages
  const loggedOut =
    location.pathname === "/login" ||
    location.pathname === "/" ||
    location.pathname === "/register" ||
    location.pathname === "/forgot-password";

  const handleMouseEnter = (e: { currentTarget: { style: { transform: string; transition: string; opacity: string; boxShadow: string; filter: string; }; }; }) => {
    e.currentTarget.style.transform = "scale(1.1) rotate(360deg)";
    e.currentTarget.style.transition = "transform 0.5s ease, opacity 1.5s ease";
    e.currentTarget.style.opacity = "0.8";
    e.currentTarget.style.filter = "brightness(0.8)";
  };

  const handleMouseLeave = (e: { currentTarget: { style: { transform: string; transition: string; opacity: string; boxShadow: string; filter: string; }; }; }) => {
    e.currentTarget.style.transform = "scale(1) rotate(0deg)";
    e.currentTarget.style.transition = "transform 0.5s ease, opacity 1.5s ease";
    e.currentTarget.style.opacity = "1";
    e.currentTarget.style.filter = "none";
  };
  return (
    <>
      <div
        style={{
          width: "100vw",
          height: useMobileDesign ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT,
          backgroundColor: "#171717",
          display: "flex",
          justifyContent: "space-between",
          position: 'sticky',
          zIndex: 999
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 30,
            height: "inherit",
            fontSize: 18,
          }}
        >
          {/* Logo and home button */}
          <img
            src={NKLOGO}
            alt="New Kharkhorum"
            width={100}
            onClick={() => setPage("home")}
            style={{
              cursor: loggedOut ? "default" : "pointer",
              height: "70%",
              transition: "transform 1s ease, opacity 0.5s ease",
              transformOrigin: "center",
              perspective: "1000px",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </div>

        {!minimalHeader && !loggedOut && !checkingStatus && (
          <>
            {!useMobileDesign && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "inherit",
                    gap: 30,
                    width: "100%",
                    overflowX: "hidden",
                  }}
                >
                  {navItems.map((item: NavItemType) =>
                    <NavItem
                      key={item.id}
                      {...item}
                      currentPage={currentPage}
                      action={() =>
                        item.action ? item.action() : setPage(item.id)
                      }
                      openDropdown={openDropdown}
                      setOpenDropdown={setOpenDropdown}
                    />
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 30,
                    height: "inherit",
                    gap: 15,
                  }}
                >

                  <div
                    title={hoverMessage}
                    onClick={() => setPage("profile")}
                    style={{ color: "white", cursor: "pointer" }}
                  >
                    <FaRegCircleUser style={{ fontSize: 28 }} />
                  </div>
                  <button
                    type="submit"
                    onClick={logout}
                    className="RoundButton"
                  >
                    Logout
                  </button>
                </div>
              </>
            )}

            {useMobileDesign && (
              <>
                <>
                  <div
                    style={{
                      padding: 20,
                      display: "flex",
                      alignItems: "center",
                      gap: 20,
                    }}
                  >
                    <div
                      onClick={() => {
                        setMobileMenuOpen(!mobileMenuOpen);
                        if (mobileMenuView) setMobileMenuView(null);
                      }}
                    >
                      {mobileMenuOpen ? (
                        <ImCross color="white" style={{ fontSize: 20 }} />
                      ) : (
                        <RxHamburgerMenu
                          color="white"
                          style={{ fontSize: 24 }}
                        />
                      )}
                    </div>

                    <div
                      title={hoverMessage}
                      style={{ color: "white", cursor: "pointer" }}
                      onClick={() => setPage("profile")}
                    >
                      <FaRegCircleUser style={{ fontSize: 20 }} />
                    </div>
                  </div>
                </>
                {mobileMenuOpen && (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        top: useMobileDesign ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT,
                        zIndex: 999,
                        width: "100%",
                        backgroundColor: "#171717",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 15,
                        padding: "15px 0px",
                        borderTop: "1px solid white",
                      }}
                    >
                      <>
                        {mobileMenuView && (
                          <button
                            className="RoundButton"
                            style={{
                              padding: "0 20px",
                              fontSize: 12,
                              height: 30,
                            }}
                            onClick={() => setMobileMenuView(null)}
                          >
                            Back
                          </button>
                        )}

                        {mobileMenuContents.map((item: NavItemType) => (
                          <NavItem
                            key={item.id}
                            {...item}
                            currentPage={currentPage}
                            overrideDropdown={true}
                            action={() => {
                              if (item.isDropdown && item.dropdownItems) {
                                setMobileMenuView(item.dropdownItems);
                              } else {
                                if (item.action) {
                                  item.action();
                                } else {
                                  setPage(item.id);
                                }
                                setMobileMenuOpen(false);
                              }
                            }}
                          />
                        ))}

                        {!mobileMenuView && (
                          <NavItem
                            id={"log-out"}
                            name={"Log Out"}
                            action={() => {
                              logout();
                              setMobileMenuOpen(false);
                            }}
                            currentPage={currentPage}
                          />
                        )}
                      </>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Header;

interface NavItemProps extends NavItemType {
  currentPage: string;
  overrideDropdown?: boolean;
  action: () => void;
  openDropdown?: string | null;
  setOpenDropdown?: (id: string | null) => void;
}

/**
 * A component that renders a navigation item.
 * The item can be a simple link or a dropdown menu with sub-items.
 *
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the navigation item.
 * @param {string} props.name - The display name of the navigation item.
 * @param {boolean} [props.private] - Whether the navigation item is private and requires special access.
 * @param {boolean} [props.isDropdown] - Whether the navigation item has a dropdown menu.
 * @param {NavItemType[]} [props.dropdownItems] - The items in the dropdown menu.
 * @param {boolean} [props.overrideDropdown] - Whether to override the default dropdown behavior.
 * @param {string} props.currentPage - The ID of the current page.
 * @param {() => void} props.action - The action to perform when the item is clicked.
 * @param {string | null} [props.openDropdown] - The ID of the currently open dropdown.
 * @param {(id: string | null) => void} [props.setOpenDropdown] - Function to set the open dropdown.
 *
 * @returns {JSX.Element} The rendered NavItem component.
 */
const NavItem: React.FC<NavItemProps> = ({
  id,
  name,
  action,
  currentPage,
  isDropdown = false,
  dropdownItems = [],
  overrideDropdown = false,
  openDropdown,
  setOpenDropdown,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const showDropdown = openDropdown === id;

  return (
    <div
      ref={dropdownRef}
      onClick={() => {
        if (isDropdown && !overrideDropdown) {
          if (setOpenDropdown) setOpenDropdown(showDropdown ? null : id);
        } else {
          action();
          if (setOpenDropdown) setOpenDropdown(null);
        }
      }}
      style={{
        color: currentPage === id || showDropdown ? "#4a74b8" : "white",
        cursor: "pointer",
        userSelect: "none",
        display: "flex",
      }}
    >
      {name}
      {isDropdown && (
        <>
          <div
            style={{
              marginLeft: 10,
              transform: showDropdown ? "rotate(180deg)" : "rotate(0deg)",
            }}
          >
            ▼
          </div>
          {showDropdown && (
            <div
              style={{
                position: "absolute",
                top: HEADER_HEIGHT + 10,
                zIndex: 999,
              }}
            >
              <Dropdown
                toggleDropdown={() => {
                  if (setOpenDropdown) setOpenDropdown(null);
                }}
                items={dropdownItems}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

interface DropdownProps {
  items: NavItemType[];
  toggleDropdown: () => void;
}

/**
 * A component that renders a dropdown menu with nested sub-items.
 *
 * @param {Object} props - The component props.
 * @param {NavItemType[]} props.items - The items in the dropdown menu.
 * @param {() => void} props.toggleDropdown - Function to toggle the dropdown menu.
 *
 * @returns {JSX.Element} The rendered Dropdown component.
 */
const Dropdown: React.FC<DropdownProps> = ({ items, toggleDropdown }) => {
  const [activeDropdowns, setActiveDropdowns] = useState<{
    [key: string]: string;
  }>({});
  const [dropdownPositions, setDropdownPositions] = useState<{
    [key: string]: any;
  }>({});

  /**
   * Handles the click event for a dropdown item.
   * If the item has secondary items, it toggles the secondary dropdown.
   *
   * @param {NavItemType} item - The dropdown item.
   * @param {React.MouseEvent} e - The click event.
   */
  const handleItemClick = (item: NavItemType, parentId: string, e: React.MouseEvent) => {
    e.stopPropagation();

    const rect = e.currentTarget.getBoundingClientRect();
    const maxHeight = window.innerHeight * 0.5; // half the screen
    const bottomSpace = window.innerHeight - rect.bottom;

    const shouldUseBottom = maxHeight > bottomSpace && rect.top > bottomSpace;
    const newPosition = {
      top: shouldUseBottom ? undefined : rect.top + window.scrollY,
      bottom: shouldUseBottom
        ? window.innerHeight - rect.top - window.scrollY
        : undefined,
      left: rect.right + 30,
    };

    if (item.dropdownItems) {
      const isActive = activeDropdowns[parentId] === item.id;
      setDropdownPositions({ ...dropdownPositions, [item.id]: newPosition });

      if (isActive) {
        const { [parentId]: _, ...rest } = activeDropdowns;
        setActiveDropdowns(rest);
      } else {
        setActiveDropdowns({ ...activeDropdowns, [parentId]: item.id });
      }
    } else {
      // No secondary items, perform the item's action
      if (item.action) item.action();
      toggleDropdown();
    }
  };



  const renderDropdownItems = (items: NavItemType[], parentId: string) => {
    return items.map((item) => (
      <div
        key={item.id}
        onClick={(e) => handleItemClick(item, parentId, e)}
        style={{
          borderBottom: "1px solid white",
          margin: "20px 0px",
          color: activeDropdowns[parentId] === item.id ? "#4a74b8" : "white",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {item.name}
        {item.dropdownItems && (
          <span style={{ marginLeft: 20, cursor: "pointer" }}>▶</span>
        )}
        {activeDropdowns[parentId] === item.id &&
          item.dropdownItems &&
          ReactDOM.createPortal(
            <div
              style={{
                position: "absolute",
                top: `${dropdownPositions[item.id]?.top}px`,
                bottom: `${dropdownPositions[item.id]?.bottom}px`,
                left: `${dropdownPositions[item.id]?.left}px`,
                backgroundColor: "rgba(0,0,0,0.7)",
                borderRadius: 10,
                padding: "10px 20px",
                whiteSpace: "nowrap",
                maxHeight: "35vh",
                overflowY: "auto",
                cursor: 'pointer'
              }}
            >
              {renderDropdownItems(item.dropdownItems, item.id)}
            </div>,
            document.body
          )}
      </div>
    ));
  };


  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,0.7)",
        borderRadius: 10,
        padding: "10px 20px",
        position: "relative",
        maxHeight: "70vh",
        overflowY: "auto",
      }}
    >
      {renderDropdownItems(items, "root")}
    </div>
  );
};
