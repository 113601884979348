import React, { CSSProperties } from "react";
import spinnerImage from "../../images/Spinner.svg";

interface SpinnerProps {
  style?: CSSProperties;
  width?: number;
  height?: number;
}

const Spinner: React.FC<SpinnerProps> = ({ style, width, height }) => {
  return (
    <div
      style={
        style
          ? style
          : {
              position: "fixed",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 50,
            }
      }
    >
      <div>
        <img
          src={spinnerImage}
          alt="Loading..."
          width={width}
          height={height}
        />
        <div style={{ textAlign: "center", color: "white" }}>
          Loading Resources...
        </div>
      </div>
    </div>
  );
};

export default Spinner;
