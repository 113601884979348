// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from 'firebase/auth';
import { getFunctions } from "firebase/functions";
import "firebase/storage";
import { getStorage } from "firebase/storage";
import { getAnalytics, setAnalyticsCollectionEnabled } from "firebase/analytics";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCZQOfgQ2qiFLPs1-GuxTUyBbAEK81l140",
  authDomain: "new-kharkhorum-dt.firebaseapp.com",
  projectId: "new-kharkhorum-dt",
  storageBucket: "new-kharkhorum-dt.appspot.com",
  messagingSenderId: "773861834381",
  appId: "1:773861834381:web:ac169027bbc542efe0acc4",
  measurementId: "G-JKR7DSXNYX"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

setAnalyticsCollectionEnabled(analytics, true);