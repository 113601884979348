import Query from "@arcgis/core/rest/support/Query";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import esriRequest from "@arcgis/core/request";
import { EXCLUDED_ATTRIBUTES, EXCLUDED_ATTRIBUTES_FROM_PANEL } from "../../../constants";
import { toTitleCase } from "../../../utils";

/**
 * Recursively fetches all features from an ArcGIS layer.
 *
 * @param {string} layerUrl - The URL of the layer to fetch features from.
 * @param {number} [start=0] - The starting index for fetching features.
 * @param {Array} [features=[]] - Accumulator for features.
 * @returns {Promise<Array>} - A promise that resolves to an array of all fetched features.
 */
export const fetchLayerFeatures: any = async (
  layerUrl: string,
  start = 0,
  features = []
) => {
  try {
    const response = await esriRequest(
      `${layerUrl}/query?where=1%3D1&outFields=*&resultOffset=${start}&resultRecordCount=1000&f=json`
    );
    const newFeatures = response.data.features;
    const allFeatures = features.concat(newFeatures);
  
    if (newFeatures.length === 1000) {
      // There might be more features to fetch
      return fetchLayerFeatures(layerUrl, start + 1000, allFeatures);
    } else {
      return allFeatures;
    }
  }
  catch(err){
    console.log(err)
  }
  
};

/**
 * Fetches all layers and their features from a given feature service URL.
 *
 * @param {string | undefined} featureLayerUrl - The URL of the feature service.
 * @returns {Promise<Array>} - A promise that resolves to an array of layers with their features.
 */
export const fetchFeatureLayers = async (
  featureLayerUrl: string | undefined
) => {
  try {
    if (!featureLayerUrl) {
      return [];
    }

    const response = await esriRequest(`${featureLayerUrl}?f=json`);
    const fetchedLayers = response.data.layers;

    // Now fetch features for each layer with pagination
    const layersWithFeatures = await Promise.all(
      fetchedLayers.map(async (layer: any) => {
        const layerFeatures = await fetchLayerFeatures(
          `${featureLayerUrl}/${layer.id}`
        );
        return {
          ...layer,
          features: layerFeatures,
          url: featureLayerUrl,
        };
      })
    );

    return layersWithFeatures;
  } catch (error) {
    console.error("Error fetching layers:", error);
  }
};

/**
 * Converts ArcGIS geometry to GeoJSON format.
 *
 * @param {string} type - The type of the geometry (e.g., "point", "polyline", "polygon").
 * @param {Object} geometry - The ArcGIS geometry object.
 * @returns {Object | null} - The converted GeoJSON geometry object or null if unsupported type.
 */
export const convertArcGISGeometryToGeoJSON = (type: string, geometry: any) => {
  if (!geometry) return null;

  switch (type) {
    case "point":
      return {
        type: "Point",
        coordinates: [geometry.x, geometry.y],
      };

    case "polyline":
      return {
        type: "LineString",
        coordinates: geometry.paths[0].map((coord: any) => [
          coord[0],
          coord[1],
        ]),
      };

    case "polygon":
      return {
        type: "Polygon",
        coordinates: [
          geometry.rings[0].map((coord: any) => [coord[0], coord[1]]),
        ],
      };

    default:
      console.warn(`Unsupported geometry type: ${type}`);
      return null;
  }
};

/**
 * Fetches all features from a given layer using a query.
 *
 * @param {FeatureLayer} layer - The feature layer to query.
 * @param {Query} query - The query to use for fetching features.
 * @returns {Promise<Array>} - A promise that resolves to an array of all fetched features.
 */
export const fetchAllFeatures = async (layer: FeatureLayer, query: Query) => {
  let allFeatures = [];
  query.start = 0;
  query.num = 2000; // Set to maxRecordCount of the server

  let queryResult = await layer.queryFeatures(query);
  allFeatures.push(...queryResult.features);

  while (queryResult.exceededTransferLimit) {
    query.start += query.num;
    queryResult = await layer.queryFeatures(query);
    allFeatures.push(...queryResult.features);
  }

  return allFeatures;
};

/**
 * Downloads layer data to the device as GeoJSON.
 *
 * @param {Array} layers - The layers to download.
 */
export const downloadLayers = async (layers: any[]) => {
  // Return if empty
  if (layers.length === 0) return;

  try {
    let features = [];

    for (const layer of layers) {
      // Query the layer for its features
      const query = layer.createQuery();
      query.outFields = ["*"];
      query.where = layer.definitionExpression || "1=1";

      const allLayerFeatures = await fetchAllFeatures(layer, query);
      features.push(
        ...allLayerFeatures.map((f) => {
          const feature = f.toJSON();
          const geometry = convertArcGISGeometryToGeoJSON(
            layer.geometryType,
            feature.geometry
          );

          return {
            type: "Feature",
            properties: feature.attributes, // Ensure properties are not null
            geometry: geometry,
          };
        })
      );
    }

    // Ensure features array does not contain invalid entries
    features = features.filter(
      (feature) => feature.geometry && feature.properties
    );

    let geojson = {
      type: "FeatureCollection",
      features: features,
    };

    const blob = new Blob([JSON.stringify(geojson)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);

    // Create a temporary link to trigger the download
    const link = document.createElement("a");
    link.download = "tnc-geo-data.geojson";
    link.href = url;
    link.click();

    // Clean up by revoking the object URL
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting geojson:", error);
  }
};

/**
 * Creates a unique value renderer for an ArcGIS layer based on a specific property and set of colors.
 *
 * @param {string} field - The field to create unique values for.
 * @param {Array<string>} colors - The array of colors to use for the unique values.
 * @param {Array} uniqueValues - The array of unique values.
 * @param {string} geometryType - The geometry type of the layer ("polygon" or "polyline").
 * @returns {UniqueValueRenderer} - The created unique value renderer.
 */
export const createUniqueValueRenderer = (
  field: string,
  colors: string[],
  uniqueValues: any[],
  geometryType: string = "esriGeometryPolygon"
) => {
  const uniqueValueInfos = uniqueValues.map((value, index) => {
    // Cycle through colors if more values than colors
    const color = colors[index % colors.length];
    let symbol;
    switch (geometryType) {
      case "esriGeometryPolygon":
        symbol = { type: "simple-fill", color: color };
        break;
      case "esriGeometryPolyline":
        symbol = { type: "simple-line", color: color, width: "2px" };
        break;
      case "esriGeometryPoint":
        symbol = { type: "simple-marker", color: color, size: "8px" };
        break;
      default:
        throw new Error(`Unsupported geometry type: ${geometryType}`);
    }

    return {
      value: value,
      symbol: symbol,
    };
  });

  return new UniqueValueRenderer({
    field: field,
    uniqueValueInfos: uniqueValueInfos,
  });
};

/**
 * Fetches all unique values of a layer attribute directly from the feature service.
 *
 * @param {string} url - The URL of the feature service.
 * @param {string} layerName - The name of the layer.
 * @param {string} attributeName - The name of the attribute to fetch unique values for.
 * @returns {Promise<Array>} - A promise that resolves to an array of unique attribute values.
 */
export const getAllUniqueAttributeValues = async (
  url: string,
  layerName: string,
  attributeName: string,
  options?: { signal?: AbortSignal }
) => {
  try {
    // Construct the URL for the specific layer within the feature service
    const layerURL = `${url}/${layerName}/query`;

    // Construct the query parameters
    const queryParams = {
      where: "1=1",
      outFields: [attributeName],
      returnDistinctValues: true,
      returnGeometry: false,
      f: "json",
    };

    // Make the request to the layer query endpoint
    const response = await esriRequest(layerURL, {
      query: queryParams,
      responseType: "json",
      signal: options?.signal,
    });

    // Extract unique values from the response, ignoring case for attribute names
    const uniqueValues = new Set();
    response.data.features.forEach((feature: any) => {
      // Find the correct attribute by ignoring case
      const attributeKey = Object.keys(feature.attributes).find(
        (key) => key.toLowerCase() === attributeName.toLowerCase()
      );
      if (attributeKey) {
        const value = feature.attributes[attributeKey];
        uniqueValues.add(value);
      }
    });

    return Array.from(uniqueValues);
  } catch (error) {
    if (error === 'AbortError') {
      console.warn('Fetch aborted:', error);
    } else {
      console.error('Error fetching unique attribute values:', error);
    }
    return [];
  }
};

interface Filter {
  values?: string[] | null;
  min?: number;
  max?: number;
}

/**
 * Constructs a definition expression for a layer to filter certain attributes.
 *
 * @param {Record<string, Filter>} filters - The filters to apply. Each filter can be an array of values or an object with min and max properties for range filtering.
 * @returns {string} - The constructed definition expression.
 */
export const constructDefinitionExpression = (
  filters: Record<string, Filter>
): string => {
  const expressions = Object.entries(filters).flatMap(
    ([attribute, filter]): string[] => {
      const { values, min, max } = filter;

      const rangeExpressions: string[] = [];
      if (min !== undefined && max !== undefined) {
        rangeExpressions.push(`${attribute} BETWEEN ${min} AND ${max}`);
      } else if (min !== undefined) {
        rangeExpressions.push(`${attribute} >= ${min}`);
      } else if (max !== undefined) {
        rangeExpressions.push(`${attribute} <= ${max}`);
      }

      const valueExpressions: string[] = [];
      if (values !== null && values !== undefined) {
        if (values.length === 0) {
          valueExpressions.push("1=0"); // If array is empty, show nothing
        } else {
          const stringifiedValues = values.map((v) => `'${v}'`).join(", ");
          valueExpressions.push(`${attribute} IN (${stringifiedValues})`);
        }
      }

      return [...rangeExpressions, ...valueExpressions];
    }
  );

  return expressions.length > 0 ? expressions.join(" AND ") : "1=1";
};

/**
 * Formats attributes for display in a popup.
 *
 * @param {Object} attributes - The attributes to format.
 * @returns {string} - The formatted HTML content.
 */
export     const formatAttributes = (attributes: any) => {
  let content = '<div style="margin: 10px;">';
  for (const key in attributes) {
    if (attributes.hasOwnProperty(key) && !EXCLUDED_ATTRIBUTES_FROM_PANEL.includes(key.toLowerCase())) {
      let value = attributes[key];
      if (value === null || value === undefined) {
        value = "N/A";
      }
      content += `<div><strong>${key}:</strong> ${value}</div>`;
    }
  }
  content += "</div>";
  return content;
};

/**
 * A mapping of ArcGIS attribute keys to more user-friendly names.
 */
export const attributeKeyMapping: { [key: string]: string } = {
  UNIQUEID: "Unique ID by Database",
  OBJECTID: "Unique ID by Feature Layer",
  PRJ_NAME: "Project Name",
  MP_VER: "Master Plan Version",
  MP_REV: "Master Plan Revision",
  MP_STA: "Master Plan Version Stage",
  DES_STATUS: "Design Status",
  CRE_DATE: "Creation date",
  APP_DATE: "Approval Date",
  MP_LEAD: "Design Lead",
  MP_SUB: "Sub Consultant",
  CRE_BY: "Created By",
  MP_REP_LI: "Master Plan Reports Link",
  PHA_ID: "Phase ID",
  PHA_RAN: "Phase Period of time",
  LAND_AREA: "Land Area",
  GFA: "Assigned GFA",
  ASG_BUA: "Assigned BUA",
  ASG_FAR: "Assigned FAR",
  POP: "Forecasted Population",
  DIS_ID: "District ID",
  DIS_NAME: "District Name",
  ZN_ID: "Masterplan Zone ID",
  UN_ID: "Urban Neighbourhood ID",
  EX_SET_NAME: "Existing Settlement Name",
  SBL_ID: "SuperBlock ID",
  BLO_ID: "Block ID",
  TC_CODE: "TC_Block Code",
  CF: "Client Designated Community Facilities",
  TC_TRANSECT: "TC_Block Transect",
  TC_LAN_USE_1: "TC_Primary Land Use",
  TC_LAN_USE_MIX: "TC_Land Use Mix",
  TC_LAN_USE_2: "TC_Secondary Land Use",
  TC_BLO_COMP: "TC_Block Composition",
  TC_ASG_FLOORS: "TC_Max Number of Floors Assigned",
  TC_FAR_AVG: "TC_Average Floor Area Ratio",
  TC_BCR_AVG: "TC_Average Block Coverage",
  TC_GFA_MAX: "TC_Max Gross Floor Area",
  TC_FAR_MIN: "TC_Min Floor Area Ratio",
  TC_FAR_MAX: "TC_Max Floor Area Ratio",
  TC_BCR_MIN: "TC_Min Block Coverage",
  TC_BCR_MAX: "TC_Max Block Coverage",
  TC_POP: "TC_Forecasted Population",
  GEO_TY: "Illustrative Geometry Type",
  RD_TY: "Road Type Name",
  RD_STY: "Road Sub-Type",
  RD_ROW: "Right Of Way",
  RD_IMG: "Road Section Link JPEG",
  RD_CAD: "Road Section Link CAD",
  PT_LIN_TY: "PT Line Type",
  PT_LIN_NAME: "PT Line Name",
  PT_STA_TY: "PT Station Type",
  PT_STA_NAME: "PT Station Name",
  PT_STA_COV: "PT Station Coverage Radius",
  LS_LAN_USE_1: "Landscape Primary Land Use",
  LS_LAN_USE_2: "Landscape Secondary Land Use",
  LS_LAN_USE_DETAIL: "Landscape Detailed Land Use",
  UT_CAN_NAME: "Canal Name",
  UT_CAN_GEL: "Canal Ground Level Elevation",
  UT_CAN_PEL: "Canal Project Level Elevation",
  UT_PIP_TY: "Pipe Type",
  UT_PIP_ELEV_START: "Pipe Start Elevation",
  UT_PIP_ELEV_END: "Pipe End Elevation",
  UT_PIP_ELEV: "Pipe Spot Elevation",
  UT_PIP_DIA: "Pipe Segment Diameter",
  UT_PIP_SLO: "Pipe Segment Slope",
  UT_PIP_LEN: "Pipe Segment Length",
  UT_MH_TY: "Manhole Type",
  UT_MH_ID: "Manhole ID",
  UT_MH_ELEV: "Manhole Spot Elevation",
  UT_BAS_TY: "Basin Type",
  UT_BAS_ID: "Basin ID",
  UT_BAS_AREA: "Basin Area",
  UT_BAS_C: "Basin C Factor",
  UT_OPT_TY: "Culvert Type",
  UT_OPT_ID: "Option ID",
  UT_CUL_BAS_AREA: "Area of Basins Discharging in Culvert",
  UT_CUL_BAS: "Basins Discharging in Culvert",
  UT_CUL_LEN: "Culvert Length",
  UT_CUL_Q: "Culvert Volumetric Flow Rate",
  UT_CUL_SIZ: "Culvert Section Size",
  UT_CUL_ID: "Culvert ID",
  UT_CUL_J: "Culvert J",
  UT_MTR_TY: "Meter Type",
  UT_MTR_ID: "Meter ID",
  UT_NOD_TY: "Node Type",
  UT_NOD_ID: "Node ID",
  UT_NOD_ELEV: "Node Elevation",
  UT_ZN: "Utilities Zone",
  UT_ZN_ID: "Utilities Zone ID",
  UT_BLD_TY: "Infrastructure Building Type",
  UT_BLD_ID: "Infrastructure Building ID",
  UT_ELC_AP: "Electric Aparant Power",
  UT_GAL_NAME: "Gallery Name",
  UT_GAL_PEL: "Gallery Project Elevation",
  UT_GAL_KM: "Gallery KM",
  fid: "Unique ID by Feature Layer",
  area: "Shape Area",
  SHAPE__Area: "Shape Area",
  SHAPE__Length: "Shape Length",
  FAR_AVG: "Average Floor Area Ratio",
  FAR_NET: "Net Floor Area Ratio",
  FAR_GRO: "Gross Floor Area Ratio",
  Count: "Count",
  BLO_TY: "Block Type",
  LAND_USE: "Land Use",
  LAND_USE_DETAILED: "Detailed Land Use",
  Bd_Ty: "Boundary Type",
  BD_TY: "Boundary Type",
  PHASE: "Phase",
  Hyd_Ty: "Hydrography Type",
  HYD_TY: "Hydrography Type",
  PHASE_DEV_AREA: "Phase Development Area",
  PHASE_DEV_AREA_ACC: "Phase Development Area Accumulated",
  PHASE_GFA: "Phase GFA",
  PHASE_GFA_ACC: "Phase GFA Accumulated",
  PHASE_LAND_AREA: "Phase Land Area",
  PHASE_POP: "Phase Population",
  PHASE_POP_ACC: "Phase Population Accumulated"
};

/** Helper function to get human-readable names */
export const getReadableName = (key: string) => {
  return attributeKeyMapping[key] || toTitleCase(key);
};

/**
 * Transforms attribute object keys to more user-friendly names based on the attributeKeyMapping.
 * This function is case-insensitive.
 *
 * @param {Object} attributes - The attributes to transform.
 * @returns {Object} - The transformed attributes.
 */
export const transformAttributes = (attributes: { [key: string]: any }) => {
  const transformed: { [key: string]: any } = {};
  const lowerCaseMapping = Object.keys(attributeKeyMapping).reduce((acc: any, key: string) => {
    acc[key.toLowerCase()] = attributeKeyMapping[key];
    return acc;
  }, {});

  for (const key in attributes) {
    const lowerCaseKey = key.toLowerCase();
    const newKey = lowerCaseMapping[lowerCaseKey] || key;
    let value = attributes[key];

    // Check if the value is a float and format it to two decimal places
    if (typeof value === "number" && !Number.isInteger(value)) {
      value = value.toFixed(2);
    }

    transformed[newKey] = value;
  }
  return transformed;
};

/**
 * Creates HTML content for a popup based on the given attributes.
 *
 * @param {Object} attributes - The attributes to display in the popup.
 * @returns {string} - The HTML content for the popup.
 */
export const createPopupContent = (attributes: { [key: string]: any }, userRole: string | null = "public"): string => {
  const excludedAttributesLowerCase = EXCLUDED_ATTRIBUTES.map(attr => attr.toLowerCase());

  const filteredAttributes = Object.fromEntries(
    Object.entries(attributes).filter(([key]) => !excludedAttributesLowerCase.includes(key.toLowerCase()))
  );

  const transformedAttributes = transformAttributes(filteredAttributes);

  const linkAttributes = [
    "MP_REP_LI",
    "RD_IMG",
    "RD_CAD",
    "Master Plan Reports Link",
    "Road Section Link JPEG",
    "Road Section Link CAD",
  ];

  const content = Object.entries(transformedAttributes)
    .map(([key, value]) => {
      let formattedValue = value;
      if (linkAttributes.includes(key)) {
        formattedValue = `<a href="${value}" target="_blank">Click Here</a>`;
      }
      return `<div><strong>${key}:</strong> ${formattedValue}</div>`;
    })
    .join("");

  return content;
};