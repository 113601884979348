import { analytics, auth } from "../firebase";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { onAuthStateChanged } from "firebase/auth";
/**
 * Function to log events with the user's email included in the event parameters.
 *
 * @param {string} eventName - The name of the event to log.
 * @param {object} eventParams - The parameters to include with the event.
 *
 * @returns {void}
 */
export const logAnalyticsEvent = (eventName: string, eventParams: object) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // Set user properties and log event
      setUserId(analytics, user.uid);
      setUserProperties(analytics, { email: user.email });

      logEvent(analytics, eventName, {
        ...eventParams,
        userEmail: user.email, // Include user email in event parameters
      });
    } else {
      // Log event without user email
      logEvent(analytics, eventName, eventParams);
    }
  });
};

/**
 * Function to calculate an intermediate color between two given colors based on a specified factor.
 *
 * @param {string} color1 - The first color in hex format.
 * @param {string} color2 - The second color in hex format.
 * @param {number} [factor=0.5] - The interpolation factor (default is 0.5).
 *
 * @returns {string} The interpolated color in hex format.
 */
const interpolateColor = (
  color1: string,
  color2: string,
  factor = 0.5
): string => {
  const result = color1
    .slice(1)
    .match(/.{2}/g)!
    .map((hex, index) => {
      const value = parseInt(hex, 16);
      return (
        value +
        Math.floor(
          (parseInt(color2.slice(1).match(/.{2}/g)![index], 16) - value) *
            factor
        )
      );
    })
    .map((value) => {
      return value.toString(16).padStart(2, "0");
    })
    .join("");

  return "#" + result;
};

/**
 * Utility function to generate random colors based on a specified color scheme.
 *
 * @param {string[]} baseColors - The base colors to use for generating the extended color scheme.
 * @param {number} length - The desired length of the extended color scheme.
 *
 * @returns {string[]} The generated extended color scheme.
 */
export const generateExtendedColorScheme = (
  baseColors: string[],
  length: number
): string[] => {
  const extendedColors: string[] = [...baseColors];
  let attempt = 0; // Initialize an attempt counter to dynamically adjust the interpolation factor

  while (extendedColors.length < length) {
    // Guard against infinite loops by breaking if attempts exceed a reasonable number
    if (attempt > length * 10) {
      console.warn(
        "Maximum attempts reached, returning current extended color scheme."
      );
      break;
    }

    for (
      let i = 0;
      i < baseColors.length - 1 && extendedColors.length < length;
      i++
    ) {
      // Adjust the factor based on the current length of the extendedColors array
      // This ensures different factors are used in each iteration to generate diverse colors
      const factor =
        (attempt % (baseColors.length - 1)) / (baseColors.length - 1);
      const newColor = interpolateColor(
        baseColors[i],
        baseColors[i + 1],
        factor
      );

      if (!extendedColors.includes(newColor)) {
        extendedColors.push(newColor);
      }
    }

    attempt++; // Increment the attempt counter after each full iteration over baseColors
  }

  return extendedColors.slice(0, length);
};

/**
 * Utility function to convert a string to Title Case, including handling hyphens and underscores.
 *
 * @param {string} str - The string to convert to Title Case.
 *
 * @returns {string} The converted string in Title Case.
 */
export const toTitleCase = (str: string) => {
  // First, replace hyphens and underscores with spaces to handle them uniformly
  const normalizedStr = str.replace(/[-_]/g, " ");

  // Then, split by spaces, capitalize each word, and join them back with a space
  return normalizedStr
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");
};
