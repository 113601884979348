import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import { FOOTER_HEIGHT, HEADER_HEIGHT } from "../../../src/constants";
import Spinner from "../Spinner";

/**
 * A component that serves as a fallback UI, displaying a header, a footer, and a spinner.
 * It is typically used to indicate a loading state or to display while content is being fetched.
 * 
 * @returns {JSX.Element} The rendered Fallback component.
 */
const Fallback: React.FC = () => {

  return (
    <>
      <Header minimalHeader={true} />
      <div
        style={{
          height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
        }}
      >
        <Spinner />
      </div>
      <Footer />
    </>
  );
};

export default Fallback;
