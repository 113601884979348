import React, { useContext } from "react";
import { FOOTER_HEIGHT, FOOTER_HEIGHT_MOBILE } from "../../../src/constants";
import { MobileContext } from "../../context/MobileContext";
//import NKAuthlogo from "../../images/kharkhorum.png";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const useMobileDesign = useContext(MobileContext);
  return (
    <>
      <div
        style={{
          width: "100vw",
          height: useMobileDesign ? FOOTER_HEIGHT_MOBILE : FOOTER_HEIGHT,
          backgroundColor: "#222",
          display: 'flex',
          justifyContent: 'space-between',
          position: 'fixed',
          bottom: 0
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 30,
            marginLeft: 30,
            height: "inherit",
            fontSize: 12,
            color: "white",
          }}
        >
          {/* <div>
            ©{currentYear} New Kharkhorum Digital Twin.
          </div> */}
        </div>
        <div style={{
          display: "flex",
          alignItems: "center",
          gap: 30,
          marginRight: 30,
          height: 'inherit',
          fontSize: 12,
          color: "white",
        }}>
          {/* <img
            src={NKAuthlogo}
            alt="New Kharkhorum Authority Logo"
            width={80}
            height={30}
            style={{ cursor: "pointer" }}
            onClick={() => window.open("https://en.kharkhorum.gov.mn/")}
            /> */}
                      <div>
            ©{currentYear} New Kharkhorum Digital Twin.
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
