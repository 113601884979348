import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer";
import esriId from "@arcgis/core/identity/IdentityManager";
import PrivateRoute from "./context/ProtectedRoute";
import { MobileDesign } from "../src/context/MobileContext";
import { LayerProvider } from "./context/LayerContext";
import { VisualisationLayerProvider } from "./context/VisualisationLayerContext";
import Header from "./components/Header";
import bg from "./images/OrkhoniiKhundii_DJI_0394.jpg";
import Fallback from "./components/Fallback";

const Masterplan = React.lazy(() => import("./pages/Masterplan"));
const RegisterPage = React.lazy(() => import("./pages/RegisterPage/index"));
const LoginPage = React.lazy(() => import("./pages/LoginPage/index"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const ForgotPassword = React.lazy(() => import("./pages/FogotPassword"));
const Home = React.lazy(() => import("./pages/Home/index"));
const Visualisation = React.lazy(() => import("./pages/Visualisation"));
const Renders = React.lazy(() => import("./pages/Visualisation/Renders"));
const Profile = React.lazy(() => import("./pages/ProfilePage/index"));
const LandingPage = React.lazy(() => import("./pages/LandingPage/index"));

const App: React.FC = () => {
  useEffect(() => {
    const handleBeforeUnload = () => {
      esriId.destroyCredentials();
    };

    // Listen for session end
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <MobileDesign>
        <LayerProvider>
          <VisualisationLayerProvider>
            <Router>
              <Suspense fallback={<Fallback />}>
                <Header />
                <Routes>
                  <Route path="/home" element={<PrivateRoute />}>
                    <Route path="/home" element={<Home />} />
                  </Route>
                  <Route path="/masterplan" element={<PrivateRoute />}>
                    <Route path="/masterplan" element={<Masterplan />} />
                  </Route>
                  <Route path="/profile" element={<PrivateRoute />}>
                    <Route path="/profile" element={<Profile />} />
                  </Route>
                  <Route path="/visualisation" element={<PrivateRoute />}>
                    <Route path="/visualisation" element={<Visualisation />} />
                  </Route>
                  <Route path="/renders" element={<PrivateRoute />}>
                    <Route path="/renders" element={<Renders />} />
                  </Route>
                  <Route path="/register" element={<RegisterPage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/*" element={<NotFound />} />
                </Routes>
                <Footer />
              </Suspense>
            </Router>

            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
          </VisualisationLayerProvider>
        </LayerProvider>
      </MobileDesign>
    </div>
  );
};

export default App;
